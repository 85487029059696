.modal-container {
  @apply fixed inset-0 z-10 overflow-y-auto;
}

.modal-wrapper {
  @apply mx-auto flex min-h-screen max-w-4xl
    items-center justify-center text-center;
}

.modal-overlay {
  @apply fixed inset-0 bg-grey-dark opacity-50 transition-opacity;
}

.modal-align {
  @apply hidden sm:inline-block sm:h-screen sm:align-middle;
}

.modal-content {
  @apply m-4 inline-block transform shadow-xl transition-all sm:align-middle;
}
