.table-wrapper {
  @apply flex flex-col;
}

.table__actions {
  @apply flex flex-wrap justify-between gap-4;
}

.table__action-btn {
  @apply flex flex-wrap justify-end gap-4;
}

.table-overflow {
  @apply -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8;
}

.table-content {
  @apply inline-block min-w-full py-8
    pr-10 align-middle sm:pl-6 lg:pl-8;
}

table {
  @apply min-w-full rounded-md shadow;
}

table thead {
  @apply border-b border-grey bg-white;
}

table tbody {
  @apply divide-y divide-grey-light bg-white-pure;
}

table tbody tr {
  @apply cursor-default;
}

table thead th,
table tbody td {
  @apply body-md px-6 text-left;
}

table thead th {
  @apply py-3 font-normal uppercase tracking-wider text-grey-dark;
}

table tbody td {
  @apply whitespace-nowrap py-2 text-black;
}

table .col--selection {
  @apply px-2 text-center;
}

table .col--selection > input[type="checkbox"] {
  @apply -mt-1;
}

table thead th.header--empty {
  @apply relative;
}

table tbody td.col--actions {
  @apply flex justify-end gap-6 px-4 align-middle;
}

table tbody td.col--actions > button {
  @apply p-2;
}

table svg {
  /* For actions displayed as icons */
  @apply h-9 w-5 cursor-pointer;
}

table div.icon-wrapper {
  @apply flex h-7 w-7 cursor-pointer items-center justify-center;
}

table .icon-tooltip {
  @apply absolute top-7 -right-5 cursor-default;
}

.table__pagination {
  @apply hidden sm:mt-4 sm:flex sm:flex-1 sm:items-center sm:justify-end;
}

.table__pagination nav {
  @apply relative z-0 inline-flex -space-x-px rounded-md shadow-sm;
}

.table__pagination nav > button {
  @apply body-md relative
    inline-flex items-center
    border border-gray-300
    bg-transparent px-4 py-2
    text-grey-dark duration-300
    hover:bg-white;
}

.table__pagination nav > button.current {
  @apply relative
    z-10
    inline-flex
    items-center
    border
    border-primary
    bg-primary-lighter
    px-4
    py-2
    text-sm
    font-medium
    text-primary;
}

.table__pagination nav > button:disabled {
  @apply bg-transparent text-grey hover:bg-transparent;
}
