.accordion-layout {
  @apply flex flex-col gap-2;
}

.accordion-row {
  @apply flex flex-col gap-0.5 print:flex-row md:flex-row;
}
.accordion-row:not(.rounded-none) {
  @apply overflow-hidden rounded-2xl;
}

.accordion-column {
  @apply flex flex-col gap-0.5 md:flex-row;
}

.accordion-section {
  @apply w-full px-8 py-4 print:px-4 print:py-2;
}

.toggle-offset {
  @apply pr-[7.125rem];
}

/* Header */
.accordion dt .accordion-section {
  @apply bg-primary text-white transition-colors duration-200 ease-in-out;
}

.accordion > dt.collapsed .accordion-section {
  @apply bg-white text-black;
}

.accordion-sublevel dt:not(.collapsed) .accordion-section {
  @apply bg-primary-lighter text-black;
}

.accordion-sublevel > dt.collapsed .accordion-section {
  @apply bg-white text-black;
}

.accordion-toggle {
  @apply flex w-full shrink-0 grow-0 items-center justify-center print:hidden md:w-20;
}
/* END Header */

/* Content */
dd .accordion-section {
  @apply bg-white text-black;
}
/* END Content */
