@import "@ng-select/ng-select/themes/default.theme";

ng-select.air-select .ng-select-container {
  @apply text-black;
}
ng-select.air-select .ng-select-container .ng-value-container .ng-placeholder {
  @apply text-grey-dark;
}
ng-select.air-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  @apply border-primary shadow-none ring-primary-light;
}
ng-select.air-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option-marked {
  @apply bg-grey-light;
}
ng-select.air-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option-selected,
ng-select.air-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option-selected.ng-option-marked {
  @apply bg-secondary-lighter;
}

ng-select.air-select.ng-invalid.ng-touched .ng-select-container {
  @apply border-error-700 shadow-none ring-error-400;
}

/**
* Add chevron
* derived from: https://stackoverflow.com/a/24651693/9742406
* */
ng-select.air-select .ng-arrow-wrapper > .ng-arrow {
  @apply absolute -top-1 left-0 border-none;
}
ng-select.air-select.ng-select-opened .ng-arrow-wrapper > .ng-arrow {
  @apply -top-1;
}
ng-select.air-select .ng-arrow-wrapper > .ng-arrow::before,
ng-select.air-select .ng-arrow-wrapper > .ng-arrow::after {
  content: "";
  /* border size = Chevron size */
  @apply absolute block border-[6px] border-solid border-transparent;
}
ng-select.air-select .ng-arrow-wrapper > .ng-arrow::before {
  /* border-color = chevron color */
  @apply top-0 border-t-grey-dark;
}
ng-select.air-select .ng-arrow-wrapper > .ng-arrow::after {
  @apply -top-[2.5px] border-t-white-pure; /* chevron thickness and bg color */
}
