.data-privacy-wrapper {
  @apply py-24 px-14;
}

.data-privacy-wrapper h1,
.data-privacy-wrapper h2,
.data-privacy-wrapper h3,
.data-privacy-wrapper h4,
.data-privacy-wrapper h5,
.data-privacy-wrapper h6 {
  @apply text-primary;
}

.data-privacy-wrapper > h3,
.data-privacy-wrapper > h4,
.data-privacy-wrapper > h5,
.data-privacy-wrapper > h6 {
  @apply mb-8 mt-12;
}

.data-privacy-wrapper > div > h3,
.data-privacy-wrapper > div > h4,
.data-privacy-wrapper > div > h5,
.data-privacy-wrapper > div > h6 {
  @apply mb-8 mt-12;
}

.data-privacy-wrapper h2 {
  @apply mb-12  mt-24;
}

.data-privacy-wrapper > p,
.data-privacy-wrapper > ul,
.data-privacy-wrapper > ol,
.data-privacy-wrapper > div > p,
.data-privacy-wrapper > div > ul,
.data-privacy-wrapper > div > ol,
.data-privacy-wrapper > ol > li {
  @apply mb-6;
}

.data-privacy-wrapper > ol {
  @apply ml-6 list-decimal;
}

.data-privacy-wrapper > ul {
  @apply ml-6 list-disc;
}
