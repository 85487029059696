:root {
  --color-neutral-purewhite: #ffffff;
  --color-neutral-white: #f4f5f7;
  --color-neutral-lightgrey: #e8edee;
  --color-neutral-grey: #c1d3d3;
  --color-neutral-darkgrey: #6b7676;
  --color-neutral-black: #2a2c2d;

  --color-primary-lighter: #dff1f1;
  --color-primary-light: #89ccca;
  --color-primary: #316d7b;
  --color-primary-dark: #295b66;

  --color-secondary-lightest: #fef8f6;
  --color-secondary-lighter: #fcece8;
  --color-secondary-light: #f5b6a8;
  --color-secondary: #ee765e;
  --color-secondary-dark: #db654d;
  --color-secondary-darker: #be4027;

  --color-error-light: #fee2e2;
  --color-error-highlight: #f87171;
  --color-error-dark: #d30000;

  --color-warning-light: #fffbeb;
  --color-warning-highlight: #fbbf24;
  --color-warning-dark: #b45309;

  --color-success-light: #ecfdf5;
  --color-success-highlight: #34d399;
  --color-success-dark: #15803d;

  --color-info-light: #ecfeff;
  --color-info-highlight: #22d3ee;
  --color-info-dark: #0e7490;

  --color-rating: #fdc635;
}
