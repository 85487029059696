@layer components {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  input[type="tel"],
  select,
  textarea {
    @apply rounded-md border-grey bg-white-pure text-black print:text-sm;
  }

  textarea {
    @apply print:overflow-hidden;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="number"]::placeholder,
  input[type="password"]::placeholder,
  input[type="tel"]::placeholder,
  input[type="date"]::placeholder {
    @apply text-grey-dark;
  }

  input[type="number"] {
    @apply text-right;
    -moz-appearance: textfield;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="password"]:focus,
  input[type="date"]:focus,
  input[type="tel"]:focus,
  select:focus,
  textarea:focus {
    @apply border-primary ring-primary-light;
  }

  input[type="text"]:disabled,
  input[type="email"]:disabled,
  input[type="number"]:disabled,
  input[type="password"]:disabled,
  input[type="date"]:disabled,
  input[type="tel"]:disabled,
  select:disabled,
  textarea:disabled,
  input[type="text"]:read-only,
  input[type="email"]:read-only,
  input[type="number"]:read-only,
  input[type="password"]:read-only,
  input[type="date"]:read-only,
  input[type="tel"]:read-only,
  textarea:read-only {
    @apply cursor-default text-grey;
  }

  input[type="text"]:disabled:focus,
  input[type="email"]:disabled:focus,
  input[type="number"]:disabled:focus,
  input[type="password"]:disabled:focus,
  input[type="date"]:disabled:focus,
  input[type="tel"]:disabled:focus,
  select:disabled:focus,
  textarea:disabled:focus,
  input[type="text"]:read-only:focus,
  input[type="email"]:read-only:focus,
  input[type="number"]:read-only:focus,
  input[type="password"]:read-only:focus,
  input[type="date"]:read-only:focus,
  input[type="tel"]:read-only:focus,
  textarea:read-only:focus {
    @apply border-grey ring-0;
  }

  textarea:read-only,
  input[type="text"]:disabled::placeholder,
  input[type="email"]:disabled::placeholder,
  input[type="number"]:disabled::placeholder,
  input[type="password"]:disabled::placeholder,
  input[type="date"]:disabled::placeholder,
  input[type="tel"]:disabled::placeholder,
  select:disabled::placeholder,
  textarea:disabled::placeholder,
  input[type="text"]:read-only::placeholder,
  input[type="email"]:read-only::placeholder,
  input[type="number"]:read-only::placeholder,
  input[type="password"]:read-only::placeholder,
  input[type="date"]:read-only::placeholder,
  input[type="tel"]:read-only::placeholder,
  textarea:read-only::placeholder {
    @apply cursor-default text-grey;
  }

  input.error,
  select.error,
  textarea.error {
    @apply border-error-700 text-error-700;
  }

  input.error:focus,
  select.error:focus,
  textarea.error:focus {
    @apply border-error-700 ring-error-400;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label.control {
    @apply body-lg relative flex cursor-pointer;
  }

  label.control.disabled {
    @apply cursor-not-allowed text-grey;
  }

  input[type="radio"],
  input[type="checkbox"] {
    @apply h-4 w-4 cursor-pointer border-grey text-secondary;
  }

  .control input[type="radio"],
  .control input[type="checkbox"] {
    @apply mr-3 mt-1;
  }

  input[type="checkbox"] {
    @apply rounded-sm;
  }

  input[type="radio"]:focus,
  input[type="radio"]:checked:focus,
  input[type="checkbox"]:focus,
  input[type="checkbox"]:checked:focus {
    @apply ring-0 ring-transparent ring-offset-0;
  }

  input[type="radio"]:checked,
  input[type="radio"]:checked:focus,
  input[type="checkbox"]:checked,
  input[type="checkbox"]:checked:focus {
    @apply border-secondary;
  }

  input[type="radio"][disabled],
  input[type="radio"]:disabled:checked {
    @apply cursor-not-allowed border-grey text-grey;
  }

  .required:after {
    content: " *";
    @apply text-secondary;
  }
}
