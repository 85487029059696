@layer utilities {
  .body-xl {
    @apply text-lg font-normal print:text-base;
  }

  .body-xl--bold {
    @apply text-lg font-bold print:text-base;
  }

  .body-lg {
    @apply text-base font-normal print:text-sm;
  }

  .body-lg--bold {
    @apply text-base font-bold  print:text-sm;
  }

  .body-md {
    @apply text-sm font-normal print:text-xs;
  }

  .body-md--bold {
    @apply text-sm font-bold print:text-xs;
  }

  .body-sm {
    @apply text-xs font-normal;
  }

  .body-sm--bold {
    @apply text-xs font-bold;
  }

  .stroke {
    @apply border-2;
  }

  .shadow-soft {
    box-shadow: 0px 12px 50px 0px rgb(107, 118, 118, 0.3); /* Grey 30% */
  }

  .shadow-hard {
    box-shadow: 0px 4px 0px 0px var(--color-primary); /* Primary */
  }

  .page-wrapper {
    @apply flex h-screen flex-col;
  }

  .page-content {
    @apply flex flex-auto flex-col px-4 py-8 md:px-8 lg:px-16;
  }

  .insurance-toggle-offset {
    @apply pr-[4.875rem] print:pr-0;
  }

  .full-height-screen {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}
