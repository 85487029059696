/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./css/colors.css";
@import "./css/utilities.css";
@import "./css/inputs.css";
@import "./css/links.css";
@import "./css/table.css";
@import "./css/modal.css";
@import "./css/insurances.css";
@import "./css/accordion.css";
@import "./css/data-privacy.css";
@import "./css/ng-select.css";

body {
  font-family: "Noto Sans", sans-serif;
  @apply bg-grey-light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
}

h1 {
  @apply text-6xl print:text-5xl;
}

h2 {
  @apply text-5xl print:text-4xl;
}

h3 {
  @apply text-4xl print:text-2xl;
}

h4 {
  @apply text-2xl print:text-xl;
}

h5 {
  @apply text-xl print:text-lg;
}

h6 {
  @apply text-lg print:text-base;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
