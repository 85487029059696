.insurance-group {
  @apply flex flex-col gap-0.5;
}

/* ---- HEADER ---- */
.insurance-group .header-wrapper {
  @apply flex gap-0.5;
}
.insurance-group .header-inner {
  @apply flex items-center bg-white px-3 py-2 text-black sm:px-4 lg:py-4;
}
.insurance-group .header-wrapper.expanded .header-inner {
  @apply bg-primary text-white;
}

/* Text section */
.insurance-group .header-text {
  @apply flex grow flex-col gap-0.5 print:grow print:flex-row lg:flex-row;
}
.insurance-group .header-wrapper.expanded .header-text {
  @apply gap-0 print:gap-0.5 lg:gap-0.5;
}
.insurance-group .header-text .header-inner {
  @apply flex-col items-start justify-start gap-2
    print:flex-row print:items-center print:justify-between
    sm:flex-row sm:items-center sm:justify-between sm:gap-6;
}
.insurance-group .header-wrapper.expanded .header-text .header-inner {
  @apply py-5 lg:py-7;
}

/* Rounded borders */
.insurance-group .header-wrapper > .header-inner:last-child {
  @apply rounded-r-2xl;
}
.insurance-group .header-wrapper.expanded > .header-inner:last-child {
  @apply rounded-br-none;
}
.insurance-group .header-wrapper > .header-inner:first-child {
  @apply rounded-l-2xl;
}
.insurance-group .header-wrapper.expanded > .header-inner:first-child {
  @apply rounded-bl-none;
}

/* ---- DETAIL ---- */
.insurance-group .detail-wrapper {
  @apply flex flex-col gap-0.5 print:flex-row lg:flex-row;
}
.insurance-group .detail-wrapper .detail-inner {
  @apply flex-1;
}
.insurance-detail .detail-selector {
  @apply flex flex-row
  items-center justify-center gap-9 bg-white py-4 px-3
  text-black print:flex-col sm:px-4 lg:flex-col;
}
.insurance-group
  .insurance-detail:last-child
  .detail-inner:first-child
  div:first-child {
  @apply print:rounded-bl-2xl lg:rounded-bl-2xl;
}
.insurance-group
  .insurance-detail:last-child
  .detail-inner:last-child
  .form-wrapper {
  @apply rounded-b-2xl print:rounded-bl-none lg:rounded-bl-none;
}

/* ---- FORM ---- */
.insurance-group .form-wrapper {
  @apply flex flex-col bg-white py-4 px-3 sm:px-4;
}
.insurance-group .form-wrapper .form-field {
  @apply flex flex-col flex-wrap gap-2 sm:flex-row sm:items-center sm:justify-between;
}
